import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Layout from "../components/layout"
import Img from "gatsby-image"

import Message from "../components/message"
import SEO from "../components/seo"

// const Smiley = styled.div`
//   max-width: 300px;
//   margin-bottom: 1.45rem;
// `

// const Row = styled.div`
//   margin-top: 10%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `

const Content = styled.main`
  margin: 0 auto;
  max-width: 1280px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  height: 100%;
`

const IndexPage = () => {

  const data = useStaticQuery(graphql`
    query {
      goodyLogo: file(relativePath: { eq: "goody-logo-grayscale.png" }) {
        childImageSharp {
          fixed(width: 48) {
            ...GatsbyImageSharpFixed
          },
        }
      },
      dvourLogo: file(relativePath: { eq: "dvour-logo.png" }) {
        childImageSharp {
          fixed(width: 42) {
            ...GatsbyImageSharpFixed
          },
        }
      },
    },
  `)

  const postComponent = (
    <div className="links">
      <ul>
        <li>
            <OutboundLink
              className="mediaLink"
              href="https://goodypoints.com"
            >
              <div className="mediaImg">
                <Img fixed={data.goodyLogo.childImageSharp.fixed} />
              </div>
              <div className="mediaContent">
                <p className="app-title">Goody Points App</p>
                <p className="app-desc">A native app providing simple parenting tools. Launching soon!</p>
              </div>
            </OutboundLink>
        </li>
        <li>
            <OutboundLink
              className="mediaLink"
              href="https://dvour.app"
            >
              <div className="mediaImg">
                <Img fixed={data.dvourLogo.childImageSharp.fixed} />
              </div>
              <div className="mediaContent">
                <p className="app-title">Dvour App (WIP)</p>
                <p className="app-desc">A web app to help you find the food you love.</p>
              </div>
            </OutboundLink>
        </li>
      </ul>
    </div>
  );

  return (
    <Layout>
        <SEO title="Home" keywords={[`mobile`, `application`]} />
          <Content>
            <Message
              content={[
                'Welcome!',
                'We design and develop stuff.',
                'Take a look at what we’re up to',
              ]}
              className="message"
              postComponent={postComponent}
            />
          </Content>
    </Layout>
  );
}

export default IndexPage
